import React from "react";

import styles from "./ListCard.module.scss";
import { OrderProps } from "../../types/order";
import { useAppDispatch } from "../../app/hooks";
import { updateIsImgModalOpen } from "../../features/settings/settingsSlice";

type Props = {
  order: OrderProps;
};

const ListCard = ({ order }: Props) => {
  const dispatch = useAppDispatch();

  const handleShowImgModal = (src: string) => {
    dispatch(updateIsImgModalOpen(src));
  };

  return (
    <div className={styles.ListCard}>
      <h5 className="text-primary-1 text-lg font-semibold">
        કામ નું આઇડી : {order.id}
      </h5>

      <div className="flex gap-4 mt-5">
        <img
          className="w-[100px] h-[100px] bg-white"
          src={`${order.image}?order=${order.id}`}
          alt=""
          // onClick={() => handleShowImgModal(order.image)}
        />

        <div className="flex flex-col gap-3">
          <p className="text-primary-1 text-sm font-semibold">
            પાર્ટી નુ નામ : {order.party.firm_name}
          </p>
          <p className="text-primary-1 text-sm font-semibold">
            આઈટમ નુ નામ : {order.item.name}
          </p>
          <p className="text-primary-1 text-sm font-semibold">
            પ્રોસેસ નુ નામ : {order.process.name}
          </p>
          <p className="text-primary-1 text-sm font-semibold">
            નંગ ની સંખ્યા : {order.qty}
          </p>
        </div>
      </div>
    </div>
  );
};
export default ListCard;
