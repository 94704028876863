import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { PartyProps } from "../../types/party";
import { GET } from "../../services/axios.service";
import { OrderProps } from "../../types/order";

export interface NewOrderState {
  capturedPhoto: string | null;
  parties: PartyProps[];
  formStep: number;
  orders: OrderProps[];
  loading: boolean;
  error: string | null;
}

const initialState: NewOrderState = {
  capturedPhoto: null,
  parties: [],
  formStep: 1,
  orders: [],
  loading: false,
  error: null,
};

export const fetchPartiesData = createAsyncThunk(
  "fetchPartiesData",
  async () => {
    let response: any;
    await GET(`/v1/worker/parties`, {})
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const newOrderSliceSlice = createSlice({
  name: "newOrderSlice",
  initialState,
  reducers: {
    updateCapturedPhoto: (state, action: PayloadAction<string | null>) => {
      state.capturedPhoto = action.payload;
    },
    updateFormStep: (state, action: PayloadAction<number>) => {
      state.formStep = action.payload;
    },
    updateOrdersData: (state, action: PayloadAction<OrderProps[]>) => {
      state.orders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartiesData.pending, (state, action: any) => {
      state.parties = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchPartiesData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.parties = action.payload.data.data;
        state.error = null;
      } else {
        state.parties = [];
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchPartiesData.rejected, (state, action: any) => {
      state.parties = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { updateCapturedPhoto, updateFormStep, updateOrdersData } =
  newOrderSliceSlice.actions;

export const getCapturedPhoto = (state: RootState) =>
  state.newOrder.capturedPhoto;

export const getParties = (state: RootState) => state.newOrder.parties;

export const getFormStep = (state: RootState) => state.newOrder.formStep;

export const getOrders = (state: RootState) => state.newOrder.orders;

export default newOrderSliceSlice.reducer;
