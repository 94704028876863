import { StylesConfig } from "react-select";

export const selectStyles: StylesConfig = {
    control: (styles) => ({ ...styles, height: "100%" }),
    placeholder: (styles) => ({
      ...styles,
      color: "#a86ae5",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#6232c8",
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#6232c8" : "#a86ae5",
      };
    },
    indicatorsContainer: (styles) => ({
      ...styles,
      color: "#a86ae5",
    }),
};