import React, { useEffect, useState } from "react";

import styles from "./OrderForm.module.scss";

import { GiCancel } from "react-icons/gi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getCapturedPhoto,
  getFormStep,
  getParties,
  updateCapturedPhoto,
  updateFormStep,
  updateOrdersData,
} from "../../features/newOrder/newOrderSlice";
import {
  Controller,
  SubmitHandler,
  useForm,
  useFieldArray,
} from "react-hook-form";
import Select from "react-select";
import { selectStyles } from "../../static/styles";
import { IFormInput, OptionsProps } from "../../types/order";
import { POSTFormData } from "../../services/axios.service";
import { route } from "../../static/route";
import { useNavigate } from "react-router-dom";

const OrderForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const capturedPhoto = useAppSelector(getCapturedPhoto);
  const parties = useAppSelector(getParties);
  const formStep = useAppSelector(getFormStep);

  const [partiesOption, setPartiesOption] = useState<OptionsProps[]>([]);
  const [itemOption, setItemOption] = useState<OptionsProps[]>([]);
  const [processOption, setProcessOption] = useState<OptionsProps[]>([]);

  const [processError, setProcessError] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    trigger,
    register,
    setValue,
    clearErrors,
    getValues,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const handleChangePhoto = () => {
    dispatch(updateCapturedPhoto(null));
  };

  const handleNextStep = async () => {
    if (formStep === 1) {
      const options: OptionsProps[] = [];
      await parties?.map((party) => {
        options.push({ value: party.id, label: party.firm_name });
      });
      setPartiesOption(options);

      dispatch(updateFormStep(formStep + 1));
    } else if (formStep === 2) {
      const isPartyValid = await trigger("party_id");
      const isItemValid = await trigger("item_id");
      if (isPartyValid && isItemValid) {
        // append({ item_id: 0, process_id: 0, qty: 0 });

        dispatch(updateFormStep(formStep + 1));
      }
    }
  };

  const handleChangeParty = async (party_id: number) => {
    if (party_id) {
      const party = parties.find((p) => p.id === party_id);
      if (party) {
        const options1: OptionsProps[] = [];
        const options2: OptionsProps[] = [];

        await party.items?.map((item) => {
          if (options1.find((o) => o.value === item.item_id) === undefined)
            options1.push({ value: item.item_id, label: item.item.name });

          options2.push({
            value: item.process_id,
            label: item.process.name,
            primary_id: item.item_id,
          });
        });

        setItemOption(options1);
        setProcessOption(options2);
      }

      setValue("item_id", 0);
      setValue("party_id", party_id);
      clearErrors("party_id");
    }
  };

  const handleChangeItem = async (item_id: number) => {
    if (item_id) {
      setValue("items", [{ item_id: item_id, process_id: 0, qty: null }]);
      setValue("item_id", item_id);
      clearErrors("item_id");
    }
  };

  const handleChangeProcess = async (
    process_id: number,
    index: number,
    primary_id: number
  ) => {
    setProcessError(null);

    const items = getValues("items");
    if (process_id && items && items.length > 0 && primary_id) {
      if (
        items.find(
          (i) => i.item_id === primary_id && i.process_id === process_id
        ) === undefined
      ) {
        setValue(`items.${index}.process_id`, process_id);
        clearErrors(`items.${index}.item_id`);
      } else {
        setProcessError(index + 1);
      }
    }
  };

  const handleAppendNewItem = () => {
    const item_id = getValues("item_id");
    if (item_id) append({ item_id: item_id, process_id: 0, qty: null });
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsLoading(true);
    setRequestError(null);

    try {
      if (data && data?.items?.length > 0 && capturedPhoto !== null) {
        const formData: any = new FormData();

        const response = await fetch(capturedPhoto);
        const image = await response.blob();

        formData.append("party_id", data.party_id);
        formData.append("image", image, "image.jpg");
        formData.append("items", JSON.stringify(data.items));

        await POSTFormData("/v1/worker/orders", formData)
          .then(async (response: any) => {
            if (response.data.status === "success") {
              await dispatch(updateFormStep(1));
              await dispatch(updateOrdersData(response.data.data || []));
              await reset();

              navigate(route.newOrderList);
            } else {
              setRequestError(response.data.message);
            }
          })
          .catch((error: any) => {
            setRequestError(error.data.message);
          });
      } else {
        setRequestError("નવા કામમાં ૧ આઈટમ ઉમેરવી જરૂરી છે.");
      }
    } catch (error) {
      setRequestError("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.");
    }

    setIsLoading(false);
  };

  return (
    <div className={styles.OrderForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formStep === 1 && capturedPhoto && (
          <>
            <div className="relative border-4 border-primary-1 rounded-sm w-full h-[300px] p-2">
              <GiCancel
                onClick={handleChangePhoto}
                className="absolute z-10 shadow bg-white top-5 left-5 text-3xl text-primary-1 border-2 border-primary-1 p-1 rounded-full"
              />

              <img
                className="w-full h-full object-contain"
                src={capturedPhoto}
                alt=""
              />
            </div>

            <div className="w-full flex justify-center">
              <button
                className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                type="button"
                onClick={handleNextStep}
              >
                આગળ વધો
              </button>
            </div>
          </>
        )}

        {formStep === 2 && (
          <div className="w-full flex flex-col justify-center">
            <div className="mt-6">
              <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 w-full relative rounded">
                <Controller
                  {...register("party_id", { required: true })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={partiesOption.find(
                        (val) => val.value === field?.value
                      )}
                      className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      styles={selectStyles}
                      placeholder={"પાર્ટી નું નામ દાખલ કરો"}
                      options={partiesOption}
                      onChange={(val: any) => handleChangeParty(val?.value)}
                    />
                  )}
                />
              </div>
              {errors.party_id && errors.party_id.type === "required" && (
                <span className="text-red-500">પાર્ટી નું નામ સિલેક્ટ કરો</span>
              )}
            </div>

            <div className="mt-6">
              <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 w-full relative rounded">
                <Controller
                  {...register("item_id", {
                    required: true,
                    min: 1,
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={
                        itemOption.find((val) => val.value === field.value) ||
                        null
                      }
                      className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      styles={selectStyles}
                      placeholder={"આઈટમ નું નામ દાખલ કરો"}
                      options={itemOption}
                      onChange={(val: any) => handleChangeItem(val?.value)}
                    />
                  )}
                />
              </div>
              {errors.item_id && (
                <span className="text-red-500">આઈટમ નું નામ સિલેક્ટ કરો</span>
              )}
            </div>

            <button
              className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
              type="button"
              onClick={handleNextStep}
            >
              આગળ વધો
            </button>
          </div>
        )}

        {formStep === 3 && (
          <div className="w-full flex flex-col justify-center">
            {fields.map((item, index) => (
              <div className="mt-6" key={index}>
                <h2 className="relative w-full text-2xl underline text-primary-1 font-bold mb-4">
                  આઈટમ - {index + 1}
                  {index > 0 && (
                    <GiCancel
                      onClick={() => remove(index)}
                      className="absolute z-10 shadow bg-white top-1 right-1 text-3xl text-primary-1 border-2 border-primary-1 p-1 rounded-full"
                    />
                  )}
                </h2>

                <div className="relative px-2 py-4 w-full mt-6 before:content[''] before:bg-gradient-to-r before:from-primary-1 before:to-primary-2 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded before:-z-[1]">
                  <div className="">
                    <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 w-full relative rounded">
                      <Controller
                        {...register(`items.${index}.process_id`, {
                          required: true,
                          min: 1,
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            value={
                              processOption.find(
                                (val) => val.value === field?.value
                              ) || null
                            }
                            className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                            styles={selectStyles}
                            placeholder={"પ્રોસેસ નું નામ દાખલ કરો"}
                            options={processOption?.filter((process) => {
                              const items = getValues("items");
                              if (items && items.length > 0) {
                                const item_id = items[index].item_id;
                                if (item_id && item_id === process.primary_id)
                                  return process;
                              }
                            })}
                            onChange={(val: any) =>
                              handleChangeProcess(
                                val?.value,
                                index,
                                val?.primary_id
                              )
                            }
                          />
                        )}
                      />
                    </div>
                    {errors?.items?.length !== undefined &&
                      errors.items[index]?.process_id !== undefined && (
                        <span className="text-red-500">
                          પ્રોસેસ નું નામ સિલેક્ટ કરો
                        </span>
                      )}

                    {processError !== null && processError === index + 1 && (
                      <span className="text-red-500">
                        {errors?.items?.length !== undefined &&
                          errors.items[index]?.process_id !== undefined &&
                          ", "}
                        આ પ્રોસેસ પેહલાં થી ઉમેરેલ છે.
                      </span>
                    )}
                  </div>

                  <div className="mt-6">
                    <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 w-full relative rounded">
                      <input
                        className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                        type="number"
                        pattern="[0-9]*"
                        placeholder="નંગ ની સંખ્યા દાખલ કરો"
                        {...register(`items.${index}.qty`, {
                          required: true,
                          min: 1,
                        })}
                      />
                    </div>
                    {errors?.items?.length !== undefined &&
                      errors.items[index]?.qty !== undefined && (
                        <span className="text-red-500">
                          નંગ ની સંખ્યા દાખલ કરો
                        </span>
                      )}
                  </div>
                </div>
              </div>
            ))}

            <button
              className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
              type="button"
              onClick={handleAppendNewItem}
            >
              પ્રોસેસ ઉમેરો
            </button>

            {requestError !== null && (
              <span className="text-red-500 text-center mt-8">
                {requestError}
              </span>
            )}

            <button
              className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "રાહ જુઓ..." : "પુરુ કરો"}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};
export default OrderForm;
