import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

interface PrivateRouteProps {
  path: string;
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, element }) => {
  const { isAuthenticated } = useAuth();
  const authToken = localStorage.getItem("authToken");

  return isAuthenticated || authToken ? (
    element
  ) : (
    <Navigate to={path} replace />
  );
};

export default PrivateRoute;
