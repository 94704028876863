import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { ImPrinter } from "react-icons/im";

import styles from "./ClosedOrderListPage.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { route } from "../../static/route";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchClosedOrdersData,
  fetchPartiesData,
  getOrders,
  getParties,
  getSelectedOrder,
  getSelectedParty,
  getTotalOrders,
  updateSelectedOrder,
  updateSelectedParty,
} from "../../features/closedOrder/closedOrderSlice";
import ListCard from "../../components/orders/ListCard";
import Select from "react-select";
import { OptionsProps, OrderProps } from "../../types/order";
import { selectStyles } from "../../static/styles";
import { FaSquareCheck } from "react-icons/fa6";
import OrderPdf from "../../components/orderDetails/OrderPdf";
import { useReactToPrint } from "react-to-print";

const printStyles = `
  @media print {
    @page {
      size: A6 portrait;
      margin: 0;
    }
  }
`;

const ClosedOrderListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let debounceTimeout: NodeJS.Timeout;
  const printRef = useRef<HTMLDivElement>(null);
  const orders = useAppSelector(getOrders);
  const totalOrders = useAppSelector(getTotalOrders);
  const parties = useAppSelector(getParties);
  const selectedParty = useAppSelector(getSelectedParty);
  const selectedOrder = useAppSelector(getSelectedOrder);

  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [partiesOption, setPartiesOption] = useState<OptionsProps[]>([]);
  const [printOrders, setPrintOrders] = useState<OrderProps[]>([]);
  const [showFilter, setShowFilter] = useState(false);

  const handleGoToBack = () => {
    navigate(route.dashboard);
  };

  const handleChangeParty = (id: number) => {
    dispatch(updateSelectedParty(id));
    setPage(1);
  };

  const handleChangeOrderId = (id: number | null) => {
    dispatch(updateSelectedOrder(id));
    setPage(1);
  };

  const handleChangePrintOrder = (order: OrderProps, action: boolean) => {
    setPrintOrders((prevOrders) => {
      if (action) {
        if (
          !prevOrders.some((existingOrder) => existingOrder.id === order.id)
        ) {
          return [...prevOrders, order];
        }
        return prevOrders;
      } else {
        return prevOrders.filter(
          (existingOrder) => existingOrder.id !== order.id
        );
      }
    });
  };

  const handlePrintOrderData = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Close Order PDF",
  });

  useEffect(() => {
    if (page === 1) setFirstLoading(true);
    setIsLoading(true);

    dispatch(
      fetchClosedOrdersData({
        page: page,
        party_id: selectedParty ? selectedParty : undefined,
        order_id: selectedOrder ? selectedOrder : undefined,
      })
    ).then(() => {
      setIsLoading(false);
      setFirstLoading(false);
    });
  }, [page, selectedParty, selectedOrder]);

  useEffect(() => {
    dispatch(fetchPartiesData());
  }, []);

  useEffect(() => {
    if (parties) {
      const options: OptionsProps[] = [];
      parties?.map((party) => {
        options.push({ value: party.id, label: party.firm_name });
      });
      setPartiesOption(options);
    }
  }, [parties]);

  return (
    <Layout>
      <div className={styles.ClosedOrderListPage}>
        <div className="w-full px-10 h-[200px] text-center flex flex-col justify-center relative">
          <FaArrowLeft
            onClick={handleGoToBack}
            className="absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 font-bold text-white">પૂરા થયેલા કામ</h1>

          {printOrders.length > 0 && (
            <ImPrinter
              onClick={handlePrintOrderData}
              className="absolute top-5 right-5 text-3xl text-white"
            />
          )}
        </div>

        <div className="w-full relative mt-[70px] px-10 py-5">
          <div className="flex mb-5">
            <button
              className="p-2 w-fit ml-auto rounded-md flex items-center bg-gradient-to-r from-primary-1 to-primary-2 text-white transition-colors duration-300"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FaSearch className="mr-2" />{" "}
              {showFilter ? "ફિલ્ટર છુપાવો" : "ફિલ્ટર બતાવો"}
            </button>
          </div>

          {showFilter && (
            <>
              <div className="bg-gradient-to-r mb-5 from-primary-1 to-primary-2 h-16 w-full relative rounded">
                <Select
                  isClearable
                  value={partiesOption.find(
                    (val) => val.value === selectedParty
                  )}
                  className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                  styles={selectStyles}
                  placeholder={"પાર્ટી નું નામ દાખલ કરો"}
                  options={partiesOption}
                  onChange={(val: any) => handleChangeParty(val?.value)}
                />
              </div>
              <div className="bg-gradient-to-r mb-10 from-primary-1 to-primary-2 h-16 w-full relative rounded">
                <input
                  defaultValue={selectedOrder || ""}
                  className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                  placeholder="કામ નું આઇડી દાખલ કરો"
                  pattern="[0-9]*"
                  type="number"
                  onKeyUp={(e: any) => {
                    const value = e.target.value;

                    clearTimeout(debounceTimeout);
                    debounceTimeout = setTimeout(() => {
                      handleChangeOrderId(value || null);
                    }, 2000);
                  }}
                />
              </div>
            </>
          )}

          {firstLoading ? (
            <p className="mt-4 text-primary-2 font-bold text-2xl text-center">
              રાહ જુઓ...
            </p>
          ) : (
            <>
              {orders && orders.length > 0 ? (
                <div>
                  {orders.map((order, index) => (
                    <div className="relative w-full h-full">
                      <Link
                        to={route.orderDetails + `/${order.id}`}
                        key={index}
                      >
                        <ListCard order={order} />
                        {printOrders.some((o) => o.id === order.id) ? (
                          <FaSquareCheck
                            onClick={(e: any) => {
                              e.preventDefault();
                              handleChangePrintOrder(order, false);
                            }}
                            className="absolute top-3 right-3 text-xl text-primary-1"
                          />
                        ) : (
                          <MdCheckBoxOutlineBlank
                            onClick={(e: any) => {
                              e.preventDefault();
                              handleChangePrintOrder(order, true);
                            }}
                            className="absolute top-3 right-3 text-xl text-primary-1"
                          />
                        )}
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  <p className="mt-4 text-primary-2 font-bold text-2xl">
                    હાલ કોઈ કામ ની વિગતો મળી નથી.
                  </p>

                  {/* <div className="w-full flex justify-center">
                <Link to={route.newOrder}>
                  <button
                    type="button"
                    className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  >
                    કામ ઉમેરો
                  </button>
                </Link>
              </div> */}
                </div>
              )}

              {totalOrders > 0 && totalOrders > orders.length && (
                <div className="flex">
                  <button
                    onClick={() => setPage(page + 1)}
                    disabled={isLoading}
                    type="button"
                    className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  >
                    {isLoading ? "રાહ જુઓ..." : "વધુ જુઓ"}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div ref={printRef} className="print:grid hidden">
        <style>{printStyles}</style>

        {printOrders.map((order, index) => (
          <OrderPdf order={order} key={index} />
        ))}
      </div>
    </Layout>
  );
};
export default ClosedOrderListPage;
