import React, { useState } from "react";

import styles from "./OrderCloseForm.module.scss";

import { SubmitHandler, useForm } from "react-hook-form";
import { ICloseFormInput, OrderProps } from "../../types/order";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getOrderDetails,
  updateOrderDetails,
} from "../../features/orderDetails/orderDetailsSlice";
import { POST } from "../../services/axios.service";

const OrderCloseForm = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(getOrderDetails);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ICloseFormInput>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | null>(null);

  const onSubmit: SubmitHandler<ICloseFormInput> = async (data) => {
    setIsLoading(true);
    setRequestError(null);

    try {
      if (data && data.delivered_qty && order && order.id) {
        const requestObj = {
          order_id: order.id,
          delivered_qty: data.delivered_qty,
        };

        await POST("/v1/worker/order-close", requestObj)
          .then(async (response: any) => {
            if (response.data.status === "success") {
              await reset();
              await dispatch(
                updateOrderDetails(response.data.data || ({} as OrderProps))
              );
            } else {
              setRequestError(response.data.message);
            }
          })
          .catch((error: any) => {
            setRequestError(error.data.message);
          });
      } else {
        setRequestError("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.");
      }
    } catch (error) {
      setRequestError("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.");
    }

    setIsLoading(false);
  };

  return (
    <div className={styles.OrderCloseForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {requestError !== null && (
          <span className="text-red-500 text-center mt-8">{requestError}</span>
        )}

        <div className="mt-6">
          <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 w-full relative rounded">
            <input
              className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
              type="number"
              pattern="[0-9]*"
              placeholder="નંગ ની સંખ્યા દાખલ કરો"
              {...register("delivered_qty", {
                required: true,
                min: 1,
              })}
            />
          </div>
          {errors.delivered_qty && (
            <span className="text-red-500">નંગ ની સંખ્યા દાખલ કરો</span>
          )}

          <div className="w-full flex">
            <button
              className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "રાહ જુઓ..." : "પુરુ કરો"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default OrderCloseForm;
