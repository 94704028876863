import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { updateCapturedPhoto } from "../../features/newOrder/newOrderSlice";

import styles from "./PhotoCapture.module.scss";

const PhotoCapture = () => {
  const dispatch = useAppDispatch();

  const [cameraError, setCameraError] = useState<string | null>(null);

  const handleCapturePhoto = async () => {
    setCameraError(null);

    try {
      // const permissions = await Camera.checkPermissions();
      // if (permissions?.camera && permissions?.camera === "granted") {
      const image = await Camera.getPhoto({
        quality: 20,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      dispatch(updateCapturedPhoto(image?.webPath || null));
      // } else {
      //   setCameraError(
      //     "કેમેરા માટે ની Permission નથી કૃપા કરી Permission આપો."
      //   );
      // }
    } catch (error) {
      setCameraError("કેમેરા માટે ની Permission નથી કૃપા કરી Permission આપો.");
    }
  };

  useEffect(() => {
    handleCapturePhoto();
  }, []);

  return (
    <div className={styles.PhotoCapture}>
      {cameraError !== null && (
        <span className="text-red-500 text-center mt-8">{cameraError}</span>
      )}

      <button
        className="mx-auto mt-8 shadow rounded-full text-2xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-7 py-[19px]"
        type="button"
        onClick={handleCapturePhoto}
      >
        ફોટો
        <br />
        પાડો
      </button>
    </div>
  );
};
export default PhotoCapture;
