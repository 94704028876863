import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft } from "react-icons/fa";

import styles from "./OrderDetailsPage.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { route } from "../../static/route";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchOrderDetailsData,
  getOrderDetails,
} from "../../features/orderDetails/orderDetailsSlice";
import { updateIsImgModalOpen } from "../../features/settings/settingsSlice";
import { FaFilePdf } from "react-icons/fa6";
import OrderCloseForm from "../../components/orderDetails/OrderCloseForm";
import OrderPdf from "../../components/orderDetails/OrderPdf";
import { useReactToPrint } from "react-to-print";

const OrderDetailsPage = () => {
  const { orderId } = useParams();
  const printRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const order = useAppSelector(getOrderDetails);

  const [isOrderCloseOpen, setIsOrderCloseOpen] = useState<boolean>(false);

  const handleGoToBack = () => {
    navigate(-1);
  };

  const handleShowImgModal = (src: string) => {
    dispatch(updateIsImgModalOpen(src));
  };

  const handlePrintOrderData = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Order PDF - " + order.id,
  });

  const handleCloseOrder = () => {
    setIsOrderCloseOpen(true);
  };

  useEffect(() => {
    if (orderId)
      dispatch(fetchOrderDetailsData({ orderId: parseInt(orderId) }));
  }, [orderId]);

  return (
    <Layout className="print:max-h-[148mm]">
      <div className={styles.OrderDetailsPage}>
        <div className="w-full px-10 h-[200px] text-center flex flex-col justify-center relative">
          <FaArrowLeft
            onClick={handleGoToBack}
            className="absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 font-bold text-white">કામ ની વિગત</h1>
        </div>

        <div className="w-full relative mt-[70px] px-10 py-5">
          {order ? (
            <div className="w-full p-6 bg-primary-2 bg-opacity-50 rounded-xl shadow mb-5">
              <h5 className="text-primary-1 text-xl font-semibold text-center">
                કામ નું આઇડી : {order?.id || ""}
              </h5>

              <div className="w-full h-[200px] mt-6">
                {order?.image && (
                  <img
                    className="w-full h-full object-contain"
                    src={`${order.image}?order=${order.id}`}
                    alt=""
                    onClick={() =>
                      handleShowImgModal(`${order.image}?order=${order.id}`)
                    }
                  />
                )}
              </div>

              <div className="flex flex-col gap-3 mt-6 text-center">
                <p className="text-primary-1 text-xl font-semibold">
                  પાર્ટી નુ નામ : {order?.party?.firm_name || ""}
                </p>
                <p className="text-primary-1 text-xl font-semibold">
                  આઈટમ નુ નામ : {order?.item?.name || ""}
                </p>
                <p className="text-primary-1 text-xl font-semibold">
                  પ્રોસેસ નુ નામ : {order?.process?.name || ""}
                </p>
                <p className="text-primary-1 text-xl font-semibold">
                  નંગ ની સંખ્યા : {order?.qty || ""}
                </p>
                {order?.delivered_qty > 0 && (
                  <p className="text-primary-1 text-xl font-semibold">
                    પરત કરેલા નંગ : {order?.delivered_qty || ""}
                  </p>
                )}
              </div>

              <div className="w-full flex flex-col">
                <button
                  className="mx-auto flex gap-3 mt-6 shadow disabled:opacity-50 rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  type="button"
                  onClick={handlePrintOrderData}
                >
                  <FaFilePdf className="text-white mt-1" />
                  PDF
                </button>

                {order?.status === 0 && !isOrderCloseOpen && (
                  <button
                    className="mx-auto flex gap-3 mt-6 shadow disabled:opacity-50 rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="button"
                    onClick={handleCloseOrder}
                  >
                    પુરુ કરો
                  </button>
                )}
              </div>

              {order?.status === 0 && isOrderCloseOpen && <OrderCloseForm />}
            </div>
          ) : (
            <div className="text-center">
              <p className="mt-4 text-primary-2 font-bold text-2xl">
                હાલ કોઈ કામ ની વિગતો મળી નથી.
              </p>

              <div className="w-full flex justify-center">
                <Link to={route.newOrder}>
                  <button
                    type="button"
                    className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  >
                    કામ ઉમેરો
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <div ref={printRef} className="print:flex hidden">
        <OrderPdf order={order} />
      </div>
    </Layout>
  );
};
export default OrderDetailsPage;
