export const route = {
  home: "/",
  login: "/",
  dashboard: "/dashboard",
  newOrder: "/new-order",
  newOrderList: "/new-order-list",
  openOrder: "/open-order",
  closedOrder: "/closed-order",
  orderDetails: "/order-details",
};
