import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";

import styles from "./DashboardPage.module.scss";
import { POST } from "../../services/axios.service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { route } from "../../static/route";
import { getLoggingUser } from "../../features/loggingUser/loggingUserSlice";
import { useAppSelector } from "../../app/hooks";

const DashboardPage = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const loggingUser = useAppSelector(getLoggingUser);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | null>(null);

  const handleLogout = async () => {
    setIsLoading(true);
    setRequestError(null);

    try {
      await POST("/v1/logout", {})
        .then(async (response: any) => {
          if (response.data.status === "success") {
            await localStorage.removeItem("authToken");
            await setIsAuthenticated(false);

            navigate(route.login);
          } else {
            setRequestError(response.data.message);
          }
        })
        .catch((error: any) => {
          setRequestError(error.data.message);
        });
    } catch (error) {
      setRequestError("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.");
    }

    setIsLoading(false);
  };

  return (
    <Layout>
      <div className={styles.DashboardPage}>
        <div className="w-full px-10 h-[200px] text-center flex flex-col justify-center">
          <h1 className="text-5xl font-bold text-white">
            {loggingUser?.name || ""}
          </h1>

          <p className="mt-4 text-white font-semibold text-xl">
            +91 {loggingUser?.phone || ""}
          </p>
        </div>

        <div className="mt-[70px] px-10 py-5">
          <div className="flex flex-col">
            <div className="flex gap-4 justify-between">
              <div
                onClick={() => navigate(route.newOrder)}
                className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 w-[50%] h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  નવું કામ
                  <br />
                  ઉમેરો
                </p>
              </div>

              <div
                onClick={() => navigate(route.openOrder)}
                className="bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 w-[50%] h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  ચાલુ કામ
                  <br />
                  અહીં જુઓ
                </p>
              </div>
            </div>

            <div
              onClick={() => navigate(route.closedOrder)}
              className="bg-primary-2 mt-5 flex shadow text-center rounded-lg bg-opacity-50 w-[100%] h-[150px]"
            >
              <p className="text-primary-1 m-auto font-bold text-lg">
                પુરા થઇ ચુકેલા
                <br />
                કામ અહીં જુઓ
              </p>
            </div>

            {requestError !== null && (
              <span className="text-red-500 text-center mt-8">
                {requestError}
              </span>
            )}

            <button
              className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
              type="button"
              disabled={isLoading}
              onClick={handleLogout}
            >
              {isLoading ? "રાહ જુઓ..." : "લોગૉઉટ કરો"}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default DashboardPage;
