import React, { useEffect, useState } from "react";

import moment from "moment";
import { OrderProps } from "../../types/order";

type Props = {
  order: OrderProps;
};

const OrderPdf = ({ order }: Props) => {
  const [rotate, setRotate] = useState(false);
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (img.height > img.width) {
        setRotate(true);
      }
    };
    img.src = order.image;
  }, [order.image]);

  useEffect(() => {
    const platform = window.navigator.platform;
    setIsIos(/Mac|iPod|iPhone|iPad/.test(platform) ? true : false);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        display: "grid",
        height: isIos ? "598px" : "148mm",
        width: "105mm",
        padding: isIos ? "0px 10px" : "5px 20px 20px 20px",
        overflow: "hidden",
      }}
    >
      <h1 className="text-2xl font-bold text-black text-center">
        {/* કનૈયા પ્લેટિંગ */}Kanaiya Plating
      </h1>
      <div className="flex justify-between mt-2">
        <div className="grid gap-2">
          <p className="text-base text-black font-semibold">
            <span className="font-bold mr-5">પાર્ટી </span>:{" "}
            {order?.party?.firm_name}
          </p>
          <p className="text-base text-black font-semibold">
            <span className="font-bold mr-[5px]">તારીખ </span>:{" "}
            {order?.created_at
              ? moment(order?.created_at, "YYYY-MM-DD HH:mm:ss").format(
                  "DD-MM-YYYY"
                )
              : ""}
          </p>
          <p className="text-base text-black font-semibold">
            <span className="font-bold mr-4">સમય </span>:{" "}
            {order?.created_at
              ? moment(order?.created_at, "YYYY-MM-DD HH:mm:ss").format(
                  "hh:mm A"
                )
              : ""}
          </p>
        </div>
        <p className="text-base text-black font-semibold">
          <span className="font-bold">ઓર્ડર નં. </span>: {order?.id}
        </p>
      </div>
      <div className="w-full h-[300px] p-5 -mt-3">
        {order?.image && (
          <img
            className={`w-full h-full object-contain ${
              rotate ? "rotate-90" : ""
            }`}
            src={`${order.image}?order=${order.id}`}
            alt=""
          />
        )}
      </div>
      <table className="w-full border-collapse -mt-3">
        <tbody>
          <tr>
            <td className="text-base text-black px-[6px] py-1 font-bold w-[90px] border border-black">
              આઈટમ :
            </td>
            <td className="text-base text-black px-[6px] py-1 font-semibold border border-black">
              {order?.item?.name || ""}
            </td>
          </tr>
          <tr>
            <td className="text-base text-black px-[6px] py-1 font-bold w-[90px] border border-black">
              પ્રોસેસ :
            </td>
            <td className="text-base text-black px-[6px] py-1 font-semibold border border-black">
              {order?.process?.name || ""}
            </td>
          </tr>
          <tr>
            <td className="text-base text-black px-[6px] py-1 font-bold w-[90px] border border-black">
              નંગ :
            </td>
            <td className="text-base text-black px-[6px] py-1 font-semibold border border-black">
              {order?.qty || ""}
            </td>
          </tr>
          <tr>
            <td className="text-base text-black px-[6px] py-1 font-bold w-[90px] border border-black">
              પરત નંગ :
            </td>
            <td className="text-base text-black px-[6px] py-1 font-semibold border border-black">
              {order?.delivered_qty || ""}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ pageBreakAfter: "always", breakAfter: "page" }}></div>
    </div>
  );
};
export default OrderPdf;
