import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { route } from "./static/route";
import NotSupportedPage from "./pages/notSupported";
import LoginPage from "./pages/login";
import NotFoundPage from "./pages/notFound";
import DashboardPage from "./pages/dashboard";
import PrivateRoute from "./components/route/PrivateRoute";
import NewOrderPage from "./pages/newOrder";
import NewOrderListPage from "./pages/newOrderList";
import ClosedOrderListPage from "./pages/closedOrderList";
import OpenOrderListPage from "./pages/openOrderList";
import OrderDetailsPage from "./pages/orderDetails";

const App = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth <= 767 ? (
        <AuthProvider>
          <Routes>
            <Route path={route.login} element={<LoginPage />} />

            <Route
              path={route.dashboard}
              element={
                <PrivateRoute element={<DashboardPage />} path={route.login} />
              }
            />

            <Route
              path={route.newOrder}
              element={
                <PrivateRoute element={<NewOrderPage />} path={route.login} />
              }
            />

            <Route
              path={route.newOrderList}
              element={
                <PrivateRoute
                  element={<NewOrderListPage />}
                  path={route.login}
                />
              }
            />

            <Route
              path={route.openOrder}
              element={
                <PrivateRoute
                  element={<OpenOrderListPage />}
                  path={route.login}
                />
              }
            />

            <Route
              path={route.closedOrder}
              element={
                <PrivateRoute
                  element={<ClosedOrderListPage />}
                  path={route.login}
                />
              }
            />

            <Route
              path={route.orderDetails + "/:orderId"}
              element={
                <PrivateRoute
                  element={<OrderDetailsPage />}
                  path={route.login}
                />
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthProvider>
      ) : (
        <NotSupportedPage />
      )}
    </>
  );
};
export default App;
