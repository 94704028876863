import React from "react";

import styles from "./ImgModal.module.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getIsImgModalOpen,
  updateIsImgModalOpen,
} from "../../features/settings/settingsSlice";
import { GiCancel } from "react-icons/gi";

const ImgModal = () => {
  const dispatch = useAppDispatch();
  const isImgModalOpen = useAppSelector(getIsImgModalOpen);

  const handleHideImgModal = () => {
    dispatch(updateIsImgModalOpen(null));
  };

  return (
    <>
      {isImgModalOpen && isImgModalOpen !== null && (
        <div className={styles.ImgModal}>
          <GiCancel
            onClick={handleHideImgModal}
            className="absolute z-10 shadow bg-white top-5 right-5 text-3xl text-primary-1 border-2 border-primary-1 p-1 rounded-full"
          />

          <img
            className="w-full h-full object-contain"
            src={isImgModalOpen}
            alt=""
          />
        </div>
      )}
    </>
  );
};
export default ImgModal;
