import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoggingUserProps } from "../../types/user";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";

export interface LoggingUserState {
    user: LoggingUserProps;
    loading: boolean;
    error: string | null;
}
  
const initialState: LoggingUserState = {
    user: {} as LoggingUserProps,
    loading: false,
    error: null,
}

export const fetchLoggingUserData = createAsyncThunk(
    'fetchLoggingUserData',
    async () => {
      let response: any;
      await GET(`/v1/logging-user`, {})
        .then((res: any) => {
          response = res;
        })
        .catch((err) => {
          response = err;
        });
      return response;
    }
);

export const loggingUserSlice = createSlice({
    name: 'loggingUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLoggingUserData.pending, (state, action: any) => {
            state.user = {} as LoggingUserProps;
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchLoggingUserData.fulfilled, (state, action: any) => {
            state.loading = false;
            if (action.payload.data.status === "success") {
                state.user = action.payload.data.data;
                state.error = null;
            } else {
                state.user = {} as LoggingUserProps;
                state.error = action?.payload?.data?.message || 'કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.';
            }
        });
        builder.addCase(fetchLoggingUserData.rejected, (state, action: any) => {
            state.user = {} as LoggingUserProps;
            state.loading = false;
            state.error = action?.payload?.data?.message || 'કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.';
        });
    },
});

export const getLoggingUser = (state: RootState) => state.loggingUser.user;

export default loggingUserSlice.reducer;