import React, { useEffect } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft } from "react-icons/fa";

import styles from "./NewOrderPage.module.scss";
import { useNavigate } from "react-router-dom";
import { route } from "../../static/route";
import PhotoCapture from "../../components/newOrder/PhotoCapture";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchPartiesData,
  getCapturedPhoto,
  getFormStep,
  updateFormStep,
} from "../../features/newOrder/newOrderSlice";
import OrderForm from "../../components/newOrder/OrderForm";

const NewOrderPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const capturedPhoto = useAppSelector(getCapturedPhoto);
  const formStep = useAppSelector(getFormStep);

  const handleGoToBack = () => {
    if (formStep > 1) dispatch(updateFormStep(formStep - 1));
    else navigate(route.dashboard);
  };

  useEffect(() => {
    dispatch(fetchPartiesData());
  }, []);

  return (
    <Layout>
      <div className={styles.NewOrderPage}>
        <div className="w-full px-10 h-[200px] text-center flex flex-col justify-center relative">
          <FaArrowLeft
            onClick={handleGoToBack}
            className="absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 font-bold text-white">નવું કામ ઉમેરો</h1>
        </div>

        <div className="w-full relative mt-[70px] px-10 py-5">
          {capturedPhoto === null ? <PhotoCapture /> : <OrderForm />}
        </div>
      </div>
    </Layout>
  );
};
export default NewOrderPage;
