import React from "react";
import Layout from "../../components/layout";

import styles from "./NotSupportedPage.module.scss";

const NotSupportedPage = () => {
  return (
    <Layout>
      <div className={styles.NotSupportedPage}>
        <h1 className="text-3xl font-bold text-white">કનૈયા પ્લેટિંગ</h1>
        <p className="text-lg font-semibold text-white">
          આ પૃષ્ઠ માત્ર મોબાઇલ ઉપકરણો પર જ સમર્થિત છે.
        </p>
      </div>
    </Layout>
  );
};
export default NotSupportedPage;
