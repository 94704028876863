import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface SettingsState {
  isImgModalOpen: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: SettingsState = {
  isImgModalOpen: null,
  loading: false,
  error: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateIsImgModalOpen: (state, action: PayloadAction<string | null>) => {
      state.isImgModalOpen = action.payload;
    },
  },
});

export const { updateIsImgModalOpen } = settingsSlice.actions;

export const getIsImgModalOpen = (state: RootState) =>
  state.settings.isImgModalOpen;

export default settingsSlice.reducer;
