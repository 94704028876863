import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import loggingUserSlice from "../features/loggingUser/loggingUserSlice";
import newOrderSlice from "../features/newOrder/newOrderSlice";
import openOrderSlice from "../features/openOrder/openOrderSlice";
import closedOrderSlice from "../features/closedOrder/closedOrderSlice";
import orderDetailsSlice from "../features/orderDetails/orderDetailsSlice";
import settingsSlice from "../features/settings/settingsSlice";

export const store = configureStore({
  reducer: {
    loggingUser: loggingUserSlice,
    newOrder: newOrderSlice,
    openOrder: openOrderSlice,
    closedOrder: closedOrderSlice,
    orderDetails: orderDetailsSlice,
    settings: settingsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
