import React, { useEffect, useRef } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft, FaFilePdf } from "react-icons/fa";

import styles from "./NewOrderListPage.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { route } from "../../static/route";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getOrders,
  updateCapturedPhoto,
} from "../../features/newOrder/newOrderSlice";
import ListCard from "../../components/orders/ListCard";
import OrderPdf from "../../components/orderDetails/OrderPdf";
import { useReactToPrint } from "react-to-print";

const printStyles = `
  @media print {
    @page {
      size: A6 portrait;
      margin: 0;
    }
  }
`;

const NewOrderListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const printRef = useRef<HTMLDivElement>(null);
  const orders = useAppSelector(getOrders);

  const handleGoToBack = () => {
    navigate(route.dashboard);
  };

  const handlePrintOrderData = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "New Order PDF",
  });

  useEffect(() => {
    dispatch(updateCapturedPhoto(null));
  }, []);

  return (
    <Layout className="new-order-print-layout">
      <div className={styles.NewOrderListPage}>
        <div className="w-full px-10 h-[200px] text-center flex flex-col justify-center relative">
          <FaArrowLeft
            onClick={handleGoToBack}
            className="absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 font-bold text-white">
            નવા કામ ની વિગત
          </h1>
        </div>

        <div className="w-full relative mt-[70px] px-10 py-5">
          {orders && orders.length > 0 ? (
            <div>
              {orders.map((order, index) => (
                <Link to={route.orderDetails + `/${order.id}`} key={index}>
                  <ListCard order={order} />
                </Link>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <p className="mt-4 text-primary-2 font-bold text-2xl">
                હાલ કોઈ નવા કામ ની વિગતો મળી નથી.
              </p>

              <div className="w-full flex justify-center">
                <Link to={route.newOrder}>
                  <button
                    type="button"
                    className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  >
                    કામ ઉમેરો
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>

        {orders && orders.length > 0 && (
          <div className="w-full flex justify-center">
            <button
              className="mx-auto flex gap-3 mt-0 mb-6 shadow disabled:opacity-50 rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
              type="button"
              onClick={handlePrintOrderData}
            >
              <FaFilePdf className="text-white mt-1" />
              PDF
            </button>
          </div>
        )}
      </div>

      <div ref={printRef} className="print:grid hidden">
        <style>{printStyles}</style>

        {orders.map((order, index) => (
          <OrderPdf order={order} key={index} />
        ))}
      </div>
    </Layout>
  );
};
export default NewOrderListPage;
