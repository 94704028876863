import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { GET } from "../../services/axios.service";
import { OrderProps } from "../../types/order";

export interface OrderDetailsState {
  order: OrderProps;
  loading: boolean;
  error: string | null;
}

const initialState: OrderDetailsState = {
  order: {} as OrderProps,
  loading: false,
  error: null,
};

export const fetchOrderDetailsData = createAsyncThunk(
  "fetchOrderDetailsData",
  async (req: { orderId: number }) => {
    let response: any;
    await GET(`/v1/worker/orders/${req.orderId}`, {})
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const orderDetailsSlice = createSlice({
  name: "orderDetails",
  initialState,
  reducers: {
    updateOrderDetails: (state, action: PayloadAction<OrderProps>) => {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderDetailsData.pending, (state, action: any) => {
      state.order = {} as OrderProps;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchOrderDetailsData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.order = action.payload.data.data;
        state.error = null;
      } else {
        state.order = {} as OrderProps;
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchOrderDetailsData.rejected, (state, action: any) => {
      state.order = {} as OrderProps;
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { updateOrderDetails } = orderDetailsSlice.actions;

export const getOrderDetails = (state: RootState) => state.orderDetails.order;

export default orderDetailsSlice.reducer;
