import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { GET } from "../../services/axios.service";
import { OrderProps } from "../../types/order";
import { PartyProps } from "../../types/party";

export interface ClosedOrderState {
  orders: OrderProps[];
  totalOrders: number;
  parties: PartyProps[];
  selectedParty: number;
  selectedOrder: number | null;
  loading: boolean;
  error: string | null;
}

const initialState: ClosedOrderState = {
  orders: [],
  totalOrders: 0,
  parties: [],
  selectedParty: 0,
  selectedOrder: null,
  loading: false,
  error: null,
};

export const fetchClosedOrdersData = createAsyncThunk(
  "fetchClosedOrdersData",
  async (req: { page: number; party_id?: number; order_id?: number }) => {
    let response: any;
    await GET(`/v1/worker/orders`, { ...req, status: 1 })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchPartiesData = createAsyncThunk(
  "fetchClosedOrdersPartiesData",
  async () => {
    let response: any;
    await GET(`/v1/worker/parties`, {})
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const closedOrderSliceSlice = createSlice({
  name: "closedOrder",
  initialState,
  reducers: {
    updateSelectedParty: (state, action: PayloadAction<number>) => {
      state.selectedParty = action.payload;
    },
    updateSelectedOrder: (state, action: PayloadAction<number | null>) => {
      state.selectedOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClosedOrdersData.pending, (state, action: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchClosedOrdersData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        if (
          action.payload.data.data.records.length > 0 &&
          action.payload.data.data.page > 1
        )
          state.orders = [...state.orders, ...action.payload.data.data.records];
        else state.orders = action.payload.data.data.records;
        state.totalOrders = action.payload.data.data.total_records;
        state.error = null;
      } else {
        state.orders = [];
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchClosedOrdersData.rejected, (state, action: any) => {
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
    builder.addCase(fetchPartiesData.pending, (state, action: any) => {
      state.parties = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchPartiesData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.parties = action.payload.data.data;
        state.error = null;
      } else {
        state.parties = [];
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchPartiesData.rejected, (state, action: any) => {
      state.parties = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { updateSelectedParty, updateSelectedOrder } =
  closedOrderSliceSlice.actions;

export const getOrders = (state: RootState) => state.closedOrder.orders;

export const getTotalOrders = (state: RootState) =>
  state.closedOrder.totalOrders;

export const getParties = (state: RootState) => state.closedOrder.parties;

export const getSelectedParty = (state: RootState) =>
  state.closedOrder.selectedParty;

export const getSelectedOrder = (state: RootState) =>
  state.closedOrder.selectedOrder;

export default closedOrderSliceSlice.reducer;
